<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'FlatService', title: 'Послуги по абоненту' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_service)"
        :modal="show_flat_service_dialog"
        :non-clickable="nonClickable"
        @updateItemModal="flatServiceUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Послуги по абоненту
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="!readOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openFlatServiceCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нову послугу</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_services"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onFlatServiceItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon :color="getIcon(item.icon, 'color')" size="26" class="mr-2">
          {{ getIcon(item.icon, 'icon') }}
        </v-icon>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.by_season="{ item }">
        <v-simple-checkbox :value="item.by_season" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_SERVICE} from "@/store/actions/flat_service";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWP_FlatServices",
  components: { MainModal },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      flat_service_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Сезонна', value: 'by_season' },
        { text: 'Місяць з', value: 'month_start' },
        { text: 'Місяць по', value: 'month_end' },
      ],
      selected_flat_service: {},
      show_flat_service_dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      flat_services: 'getFlatServices',
      flat: 'getFlat',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonth',
    }),
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_SERVICE
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    flatServiceUpdateItemModal(payload) {
      this.show_flat_service_dialog = false
      this.selected_flat_service = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openFlatServiceCreateDialog() {
      this.selected_flat_service = {
        person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash,
        date_start: this.current_month
      }
      this.show_flat_service_dialog = true
    },
    onFlatServiceItemClick(payload) {
      if (this.readOnly || this.nonClickable) {
        this.selected_flat_service = {}
        this.show_flat_service_dialog = false
        return
      }
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      local_payload.update = true
      this.selected_flat_service = local_payload
      this.show_flat_service_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchFlatServices(person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFlatServices(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>